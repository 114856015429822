import { useEffect, useState } from 'react';
import styles from './GDPRBanner.module.scss'

const GDPRBanner = () => {
    const [seen, setSeen] = useState<string | null>();
    useEffect(() => {
        setSeen(localStorage.getItem('gdprSeen'));
    }, []);

    if(seen === 'yes') return null;

    const seenClicked = () => {
        localStorage.setItem('gdprSeen', 'yes');
        setSeen('yes');
    }

    return (
        <div className={styles.wrapper} >
            <p>Ta strona wykorzystuje pliki cookie. Tutaj dowiesz się więcej.</p>
            <button onClick={seenClicked}>Rozumiem</button>
        </div>
    )
}

export default GDPRBanner;