import { ReactNode, useEffect } from "react";
import Container from "../Container/Container";
import Footer from "../Footer/Footer";
import GDPRBanner from "../GDRPBanner/GDPRBanner";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import styles from "./Layout.module.scss";
import Banner from "../BottomBanner/BottomBanner";

type LayoutProps = {
    children?: ReactNode;
    title: string;
};

const Layout = ({ children, title }: LayoutProps): JSX.Element => {
    return (
        <>
            <Menu />
            <div className={styles.wrapper}>
                <Header title={title} />
                <Container>{children}</Container>
                <Footer />
                <Banner />
                <GDPRBanner />
            </div>
        </>
    );
};

export default Layout;
