import Image from "next/image";
import Link from "next/link";
import { FaFacebook, FaFacebookF, FaTimes } from "react-icons/fa";
import { useUIContext } from "../../contexts/UIContext";
import Container from "../Container/Container";
import styles from "./Menu.module.scss";

type MenuProps = {};

const Menu: React.FC<MenuProps> = () => {
    const { showMenu, setShowMenu } = useUIContext();
    return (
        <div
            className={[styles.wrapper, showMenu ? styles.open : null].join(
                " "
            )}
        >
            <Container>
                <div className={styles.navWrapper}>
                    <div className={styles.brand}>
                        <div className={styles.logo}>
                            <Image
                                src="/images/logo3.png"
                                height={100}
                                width={500}
                                layout="responsive"
                                alt="Wiadomości logo"
                            />
                        </div>
                    </div>
                    <FaTimes
                        size={32}
                        color="white"
                        onClick={() => setShowMenu(false)}
                    />
                </div>
                <nav className={styles.nav}>
                    <ul>
                        <li>
                            <Link href="/">
                                <a onClick={() => setShowMenu(false)}>Strona Główna</a>
                            </Link>
                        </li>
                        <li>
                            <Link href="/categories/UK">
                                <a onClick={() => setShowMenu(false)}>UK</a>
                            </Link>
                        </li>
                        <li>
                            <Link href="/categories/Podróże">
                                <a onClick={() => setShowMenu(false)}>
                                    Podróże
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link href="/sciagawka">
                                <a onClick={() => setShowMenu(false)}>Ściągawki</a>
                            </Link>
                        </li>
                    </ul>
                </nav>
                <ul className={styles.socialLinks}>
                    <li>
                        <a href="https://www.facebook.com/aleksandrafiddler.uk" aria-label="Facebook">
                            <FaFacebook size={32} />
                        </a>
                    </li>
                </ul>
                <div className={styles.links}>
                        <ul>
                            <li>
                                <Link href="/ochrona">
                                <a onClick={() => setShowMenu(false)}>Ochrona prywatności</a></Link>
                            </li>
                            <li>
                                <Link href="/reklama">
                                <a onClick={() => setShowMenu(false)}>kontakt do reklamowania</a></Link>
                            </li>
                        </ul>
                    </div>
            </Container>
        </div>
    );
};

export default Menu;
