import Container from "../../components/Container/Container";
import styles from "./BottomBanner.module.scss";

type BannerProps = {};

const Banner: React.FC<BannerProps> = () => {
    return (
        <section className={styles.wrapper}>
            <Container>
                <div className={styles.innerWrapper}>
                    <div>Copyright 2023 © Total English Limited</div>
                    <div className={styles.sponsor}>
                        Website developed and managed by{" "}
                        <a href="https://uncommonweb.co.uk">
                            <img
                                className={styles.uncommonLogo}
                                src="/images/uncommon.svg"
                                alt="Uncommon Wed Deisgn Logo"
                            />
                        </a>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default Banner;
