import Link from 'next/link';
import { TiSocialFacebook } from "react-icons/ti";
import Container from "../Container/Container";
import styles from "./Footer.module.scss";

const Footer = () => {
    return (
        <div className={styles.wrapper}>
            <Container>
                <footer className={styles.footer}>
                    <div className={styles.main}>
                        <img src="/images/logo3.png" alt="" />
                        <div>
                            <ul className={styles.socialLinks}>
                                <li>
                                    <a
                                        href="https://www.facebook.com/aleksandrafiddler.uk"
                                        target="_blank"
                                        rel="noreferrer"
                                        aria-label="Facebook"
                                    >
                                        <TiSocialFacebook size={30} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.links}>
                        <ul>
                            <li>
                                <Link href="/ochrona">
                                <a>Ochrona prywatności</a></Link>
                            </li>
                            <li>
                                <Link href="/reklama">
                                <a>kontakt do reklamowania</a></Link>
                            </li>
                        </ul>
                    </div>
                </footer>
            </Container>
        </div>
    );
};

export default Footer;
