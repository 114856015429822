type GoogleAdProps = {
    client?: string;
    slot: string;
    format?: string;
    responsive?: boolean;
    layout?: string;
    style?: object;
};

{/* <ins class="adsbygoogle"
     style="display:block; text-align:center;"
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-8205632245269151"
     data-ad-slot="8795943766"></ins>


     <ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-8205632245269151"
     data-ad-slot="2671612442"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins> */}

const GoogleAd = ({
    client = "ca-pub-8205632245269151",
    slot = "2671612442",
    format = "auto",
    responsive,
    layout,
    style = { display: "block" },
}: GoogleAdProps): JSX.Element => {
    const props: any = {
        className: "adsbygoogle",
        style,
        "data-ad-client": client,
        "data-ad-slot": slot,
        "data-ad-format": format,
        "data-full-width-responsive": responsive,
    };

    if (layout?.length) {
        props["data-add-layout"] = layout;
    }

    if (responsive) {
        props["data-full-width-responsive"] = responsive;
    }

    if (layout?.length) {
        props["style"] = style;
    }

    return <ins {...props}></ins>;
};

export default GoogleAd;
