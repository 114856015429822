import { ReactNode } from "react";
import styles from "./Container.module.scss";

type ContainerProps = {
    children?: ReactNode;
};

const Container = ({ children }: ContainerProps): JSX.Element => {
    return <div className={styles.wrapper}>{children}</div>;
};

export default Container;
