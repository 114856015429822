import { useRouter } from "next/router";
import Image from "next/image";

import styles from "./Header.module.scss";
import Container from "../Container/Container";
import { FaBars, FaHome, FaSearch } from "react-icons/fa";
import { useUIContext } from "../../contexts/UIContext";
import Link from "next/link";

type HeaderProps = {
  title: string;
};

const Header = ({ title }: HeaderProps): JSX.Element => {
  const { setShowMenu } = useUIContext();
  const router = useRouter();

  const showHomeLink = () => {
    if (router.route === "/") return false;
    return true;
  };

  return (
    <header className={styles.wrapper}>
      <Container>
        <div className={styles.navWrapper}>
          {showHomeLink() && (
            <Link href="/">
              <FaHome className={styles.homeButton} />
            </Link>
          )}
          <div className={styles.logo}>
            <Image
              src="/images/logo3.png"
              height={100}
              width={500}
              layout="responsive"
              alt="Wiadomości"
            />
          </div>
          <div className={styles.buttons}>
            <Link href="/search">
              <FaSearch className={styles.search} size={32} color="white" />
            </Link>
            <FaBars
              className={styles.navToggle}
              size={32}
              color="white"
              onClick={() => setShowMenu(true)}
            />
          </div>
        </div>
      </Container>
      <Container>
        <div className={styles.mainAd}>
          <div className="adplugg-tag" data-adplugg-zone="under_nav"></div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
